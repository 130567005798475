/* eslint-disable eqeqeq */
import React, { Fragment, useState } from "react";
import { getProfitLoss, getProfitLossPercentage } from "../../utils/positions";

const OrderDisplay = ({
  order,
  client,
  pos,
  handleShowSquareOff,
  handleShowStopLoss,
  group,
}) => {
  const [record] = useState(order);

  const slqty = record?.cover_quantity === "0" ? "" : record?.cover_quantity;

  return (
    <Fragment>
      <tbody className="margin-tbody">
        <tr className="margin-tr">
          <td className="all-normal-text p-2 ">
            <div style={{ width: "210px" }}>{record?.contract || '-'}</div>
          </td>
          <td className="text-center all-normal-text p-2">
            <div style={{ width: "49px" }}>{record?.action}</div>
          </td>
          <td align="right" className="all-normal-text p-2">
            <div style={{ width: "32px" }}>{record?.quantity}</div>
          </td>
          <td align="right" className=" all-normal-text p-2">
            <div style={{ width: "56px" }}>{record?.action === "Sell" ? slqty : ""}</div>
          </td>
          <td align="right" className=" all-normal-text p-2">
            <div style={{ width: "56px" }}>{record?.action === "Buy" ? slqty : ""}</div>
          </td>
          <td align="right" className="all-normal-text p-2">
            <div style={{ width: "56px" }}>{parseFloat(record?.average_price || 0).toFixed(2)}</div>
          </td>
          <td align="right" className="all-normal-text p-2">
            <div style={{ width: "60px" }}>{parseFloat(record?.ltp || 0).toFixed(2)}</div>
          </td>
          <td align="right" className="all-normal-text p-2">
            <div style={{ width: "60px" }}>
              {((record?.action === "Buy"
                ? record.ltp - record.average_price
                : record?.average_price - record?.ltp) > 0
                ? "+"
                : "") +
                (record?.action === "Buy"
                  ? record?.ltp - record?.average_price
                  : record?.average_price - record?.ltp
                ).toFixed(2)}
            </div>
          </td>
          <td
            align="right"
            className={`${
              getProfitLossPercentage(record).pnl < 0
                ? "loss-color"
                : "profit-color"
            }
            all-normal-text p-2
                `}
          >
            <div style={{ width: "60px" }}>
              {getProfitLossPercentage(record).prefix +
                getProfitLossPercentage(record).pnl.toFixed(2)}
              %
            </div>
          </td>
          <td
            align="right"
            className={`${
              getProfitLoss(record).pnl < 0 ? "loss-color" : "profit-color"
            } all-normal-text p-2`}
          >
            <div style={{ width: "80px" }}>
              {getProfitLoss(record).prefix +
                getProfitLoss(record).pnl.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </div>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <div
              onClick={() =>
                handleShowStopLoss(client, record, pos.broker, group)
              }
              style={{ cursor: "pointer", width: "80px" }}
            >
              Stop&nbsp;Loss
            </div>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <div
              onClick={() =>
                handleShowSquareOff(
                  client,
                  record,
                  pos.broker,
                  "reOrder",
                  group
                )
              }
              style={{ cursor: "pointer", width: "80px" }}
            >
              Reorder
            </div>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <div
              onClick={() =>
                handleShowSquareOff(client, record, pos.broker, "mono", group)
              }
              style={{ cursor: "pointer", width: "80px" }}
            >
              Square&nbsp;Off
            </div>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <div
              onClick={() =>
                handleShowSquareOff(client, record, pos.broker, "all", group)
              }
              style={{ cursor: "pointer", width: "80px" }}
            >
              Square&nbsp;Off&nbsp;All
            </div>
          </td>
        </tr>
      </tbody>
    </Fragment>
  );
};

export default OrderDisplay;
