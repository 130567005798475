/* eslint-disable eqeqeq */
import { capitalize } from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "is-empty";
import { useFormik } from "formik";
import { forceClose, openToast } from "../../../redux/slice/toastSlice";
import {
  getClientById,
  getIndexData,
  getIndexIdentifiers,
  postPlaceOrder,
} from "../../../services/admin.service";
import moment from "moment";
import DraggableModal from "../../draggableModal/DraggableModal";

const initialValues = {
  broker: "",
  index: "",
  expiry: "",
  strike: "",
  call: "",
  action: "Sell",
  quantity: "",
  ltp: "",
  distance: "",
  matchedStrike: "",
};

const sortedArray = (list) => {
  // let list = ["256265", "260105", "257801", "274441"];
  return list;
};

function formattedExpiry(date) {
  let diff = moment
    .utc(date, "YYYY-MM-DD")
    .diff(new Date().setHours(0, 0, 0, 0), "days");
  if (diff == 0) return moment.utc(date).format("DD-MMM") + " (Today)";
  return (
    moment.utc(date).format("DD-MMM") +
    ` (${moment
      .utc(date, "YYYY-MM-DD")
      .diff(new Date().setHours(0, 0, 0, 0), "days")} days)`
  );
}

function findNearestStrike(price, strikes) {
  let sortedStrikes = strikes.sort((a, b) => a.strikePrice - b.strikePrice);
  let matchedStrike = sortedStrikes.findIndex(
    (item) => Math.abs(item.strikePrice / 100) > price
  );
  return sortedStrikes[matchedStrike - 1];
}

const formatResponseMessage = (message = "") => {
  if (!message) return "";
  else if (!message.includes(".")) return message;
  else return message.split(".")[0];
};

const PlaceOrderV2 = ({ open, onHide, user, fetchPositionsData }) => {
  const { placeOrderIndexes } = useSelector((state) => state?.indexes);
  const [indexValues] = useState(
    placeOrderIndexes?.map((indexItem) => indexItem?.instrument_token)
  );
  const dispatch = useDispatch();
  const [index, setIndex] = useState({});
  const [indexData, setIndexData] = useState();
  const [allIndex, setAllIndex] = useState([]);
  const [loader, setLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [client, setClient] = useState({});
  const [margin, setMargin] = useState("0.00");
  const [isChecked, setIsChecked] = useState(false);
  const [identifiers, setIdentifiers] = useState({});

  const initialDistanceFetch = useRef(true);

  const { values, handleChange, handleSubmit, setValues, resetForm } =
    useFormik({
      initialValues: {
        ...initialValues,
        broker: user.broker,
        index: indexValues[0],
      },
      onSubmit: submitOrder,
    });

  const handleHide = () => {
    dispatch(forceClose());
    resetForm();
    onHide();
  };

  const fetchIdentifiers = useCallback(async () => {
    try {
      const { success, message, result } = await getIndexIdentifiers();
      if (success) {
        setIdentifiers(result);
      } else {
        dispatch(
          openToast({
            message: formatResponseMessage(message),
            type: "error",
            autoHide: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Some error occurred while fetching identifiers",
          type: "error",
          autoHide: false,
        })
      );
    }
  }, [dispatch]);

  async function submitOrder(values, { resetForm }) {
    try {
      setSubmitLoader(true);
      const { success, message } = await postPlaceOrder({
        ...values,
        broker: user.broker,
        userId: user._id,
      });

      if (success) {
        setSubmitLoader(false);
        await fetchPositionsData();
      } else {
        dispatch(
          openToast({
            message: formatResponseMessage(message),
            type: "error",
            autoHide: false,
          })
        );
        setSubmitLoader(false);
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Some error occurred",
          type: "error",
          autoHide: false,
        })
      );
      setSubmitLoader(false);
    }
  }

  const fetchAllIndexData = useCallback(async () => {
    try {
      if (isEmpty(indexValues) && !open) return;
      if (initialDistanceFetch.current || !open) {
        initialDistanceFetch.current = false;
        return;
      }
      const allResponse = await Promise.allSettled(
        indexValues.map((item) => getIndexData(item))
      );
      console.log("allResponse: ", allResponse);

      setAllIndex((prev) => {
        let temp = [];
        allResponse.forEach((response, index) => {
          if (response.status === "fulfilled") {
            temp[index] = response.value;
          }
        });
        return temp;
      });
    } catch (error) {
      dispatch(
        openToast({
          message: "Some error occurred while fetching index data",
          type: "error",
        })
      );
    }
  }, [dispatch, indexValues, open]);

  const fetchIndexData = useCallback(async () => {
    try {
      await setValues((prev) => ({
        ...prev,
        quantity: "",
        strike: "",
        expiry: "",
        ltp: "",
        distance: "",
        matchedStrike: "",
      }));
      if (isEmpty(values.index) || !open || isEmpty(allIndex)) return;
      setLoader(true);
      const { success, result, index, message } = allIndex.find(
        (item) => item.index.instrument_token == values.index.toString()
      );

      if (success) {
        setLoader(false);
        setIndex(index);
        setIndexData(result);
        let nearestStrike = findNearestStrike(
          result?.livePrice?.value,
          result?.optionChain?.optionChains
        );
        setValues((prev) => ({
          ...prev,
          expiry: result?.optionChain?.expiryDetailsDto?.expiryDates[0],
          call: prev.call || "CE",
          strike: Math.abs(nearestStrike.strikePrice / 100),
          matchedStrike: nearestStrike,
          ltp:
            (prev.call || "PE") === "PE"
              ? parseFloat(nearestStrike.putOption.ltp).toFixed(2)
              : parseFloat(nearestStrike.callOption.ltp).toFixed(2),
          distance: (
            Math.abs(nearestStrike.strikePrice / 100) - result?.livePrice?.value
          ).toFixed(0),
          distancePercentage: (
            ((Math.abs(nearestStrike.strikePrice / 100) -
              result?.livePrice?.value) *
              100) /
            result?.livePrice?.value
          ).toFixed(2),
          broker: user.broker,
          quantity: index?.lot_size,
        }));
      } else {
        dispatch(
          openToast({
            message: message,
            type: "error",
            autoHide: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Some error occurred",
          type: "error",
          autoHide: false,
        })
      );
    }
  }, [setValues, values.index, open, allIndex, user.broker, dispatch]);

  const fetchDistanceData = useCallback(
    async (expiry) => {
      try {
        if (isEmpty(values.index) || !open || isEmpty(values.strike)) return;
        setLoader(true);
        const { success, result, index, message } = await getIndexData(
          values.index,
          expiry
        );

        if (success) {
          setLoader(false);
          setIndex(index);
          setIndexData(result);
          let nearestStrike = result?.optionChain?.optionChains.find(
            (item) =>
              Math.abs(item.strikePrice / 100).toString() ==
              values.strike.toString()
          );
          if (isEmpty(nearestStrike)) return;
          setValues((prev) => ({
            ...prev,
            matchedStrike: nearestStrike,
            ltp:
              (prev.call || "PE") === "PE"
                ? parseFloat(nearestStrike.putOption.ltp).toFixed(2)
                : parseFloat(nearestStrike.callOption.ltp).toFixed(2),
            distance: (
              Math.abs(nearestStrike.strikePrice / 100) - result.livePrice.value
            ).toFixed(0),
            distancePercentage: (
              ((Math.abs(nearestStrike.strikePrice / 100) -
                result.livePrice.value) *
                100) /
              result.livePrice.value
            ).toFixed(2),
            expiry: expiry,
          }));
        } else {
          dispatch(
            openToast({
              message: message,
              type: "error",
            })
          );
        }
      } catch (error) {
        console.log(error);
        dispatch(
          openToast({
            message: "Some error occurred",
            type: "error",
          })
        );
      }
    },
    [values.index, values.strike, open, setValues, dispatch]
  );

  const fetchClientData = useCallback(async () => {
    try {
      if (isEmpty(user?._id)) return;
      const response = await getClientById(user?._id);
      if (response.success) {
        setClient(response?.result);
      } else {
        setClient({});
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }, [user?._id]);

  useEffect(() => {
    fetchClientData();
  }, [fetchClientData]);

  useEffect(() => {
    fetchIdentifiers();
  }, [fetchIdentifiers]);

  // useEffect(() => {
  //   if (!isEmpty(values.expiry)) {
  //     fetchDistanceData(values.expiry);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.expiry]);

  useEffect(() => {
    fetchAllIndexData();
  }, [fetchAllIndexData]);

  useEffect(() => {
    fetchIndexData();
  }, [fetchIndexData]);

  const handleChangeStrike = useCallback(
    (e) => {
      if (isEmpty(indexData)) return;

      let matchedStrike = indexData?.optionChain?.optionChains.find(
        (item) =>
          Math.abs(item.strikePrice / 100).toString() ==
          e.target.value.toString()
      );

      setValues((prev) => ({
        ...prev,
        matchedStrike,
        strike: Math.abs(matchedStrike?.strikePrice / 100),
        distance: (
          Math.abs(matchedStrike.strikePrice / 100) - indexData.livePrice.value
        ).toFixed(0),
        distancePercentage: (
          ((Math.abs(matchedStrike.strikePrice / 100) -
            indexData.livePrice.value) *
            100) /
          indexData.livePrice.value
        ).toFixed(2),
      }));
      handleChange(e);
    },
    [handleChange, indexData, setValues]
  );

  const handleQuantityBlur = (e) => {
    if (isEmpty(index)) return;
    let lotSize = parseInt(index.lot_size);

    let newQty = parseInt(e.target.value);
    if (isNaN(newQty)) newQty = 0;

    if (newQty % lotSize > lotSize / 2) {
      newQty = newQty + lotSize - (newQty % lotSize);
    } else {
      newQty = newQty - (newQty % lotSize);
    }
    if (newQty < 0) {
      setValues((prev) => ({ ...prev, quantity: 0 }));
    } else {
      setValues((prev) => ({ ...prev, quantity: parseInt(newQty) }));
    }
  };

  const roundOffQty = useCallback(
    (newQty) => {
      if (isEmpty(index)) return;
      let lotSize = parseInt(index?.lot_size);

      if (newQty % lotSize > lotSize / 2) {
        newQty = newQty + lotSize - (newQty % lotSize);
      } else {
        newQty = newQty - (newQty % lotSize);
      }
      if (newQty < 0) {
        return 0;
      } else {
        return parseInt(newQty);
      }
    },
    [index]
  );

  const handleQuantityButtons = (type = "inc") => {
    if (isEmpty(index)) return;
    let lotSize = parseInt(index.lot_size);

    if (type == "inc") {
      setValues((prev) => ({
        ...prev,
        quantity:
          (typeof prev.quantity == "number" ? prev.quantity : 0) +
          parseInt(lotSize),
      }));
    } else {
      setValues((prev) => {
        let newQty =
          (typeof prev.quantity == "number" ? prev.quantity : 0) -
          parseInt(lotSize);
        if (newQty < 0) return prev;

        return {
          ...prev,
          quantity: newQty,
        };
      });
    }
  };

  const handleExpiryButtons = async (type = "inc") => {
    if (isEmpty(indexData)) return;
    let currNum =
      indexData?.optionChain?.expiryDetailsDto?.expiryDates.findIndex(
        (item) => item == values.expiry
      );

    if (type == "inc") {
      if (
        currNum >=
        indexData?.optionChain?.expiryDetailsDto?.expiryDates.length - 1
      ) {
        return;
      } else {
        let newExpiry =
          indexData?.optionChain?.expiryDetailsDto?.expiryDates[currNum + 1];
        await setValues((prev) => ({ ...prev, expiry: newExpiry }));
        await fetchDistanceData(newExpiry);
      }
    } else {
      if (currNum <= 0) {
        return;
      } else {
        let newExpiry =
          indexData?.optionChain?.expiryDetailsDto?.expiryDates[currNum - 1];
        await setValues((prev) => ({ ...prev, expiry: newExpiry }));
        await fetchDistanceData(newExpiry);
      }
    }
  };

  const handleExpiryChange = (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, expiry: e.target.value }));
    fetchDistanceData(e.target.value);
  };

  const handleStrikeButtons = (type = "inc") => {
    if (isEmpty(indexData)) return;
    let currStrikeData = isEmpty(values.matchedStrike)
      ? Math.abs(values.strike * 100)
      : values.matchedStrike?.strikePrice;
    let currPos = indexData?.optionChain?.optionChains?.findIndex(
      (item) => item.strikePrice.toString() === currStrikeData.toString()
    );

    if (type == "inc") {
      if (currPos >= indexData?.optionChain?.optionChains.length - 1) {
        return;
      } else {
        let newStrike = indexData?.optionChain?.optionChains[currPos + 1];

        setValues((prev) => ({
          ...prev,
          matchedStrike: newStrike,
          strike: Math.abs(newStrike.strikePrice / 100),
          distance: (
            Math.abs(newStrike.strikePrice / 100) - indexData.livePrice.value
          ).toFixed(0),
          distancePercentage: (
            ((Math.abs(newStrike.strikePrice / 100) -
              indexData.livePrice.value) *
              100) /
            indexData.livePrice.value
          ).toFixed(2),
        }));
      }
    } else {
      if (currPos <= 0) {
        return;
      } else {
        let newStrike = indexData?.optionChain?.optionChains[currPos - 1];
        setValues((prev) => ({
          ...prev,
          matchedStrike: newStrike,
          strike: Math.abs(newStrike.strikePrice / 100),
          distance: (
            Math.abs(newStrike.strikePrice / 100) - indexData.livePrice.value
          ).toFixed(0),
          distancePercentage: (
            ((Math.abs(newStrike.strikePrice / 100) -
              indexData.livePrice.value) *
              100) /
            indexData.livePrice.value
          ).toFixed(2),
        }));
      }
    }
  };

  const handleChangeIndex = (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, expiry: "" }));
    return handleChange(e);
  };

  const handleAvailableMargin = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      let result;
      if (values.action === "Sell") {
        result = client?.margin;
        const newQty = (client?.margin / index?.margin) * index.lot_size;
        setValues((prev) => ({
          ...prev,
          quantity: roundOffQty(newQty),
        }));
      } else if (values.action === "Buy") {
        const strike = isEmpty(values.matchedStrike)
          ? values.ltp || "N/A"
          : values.call === "PE"
          ? values.matchedStrike?.putOption.openInterest
            ? parseFloat(values.matchedStrike?.putOption.ltp).toFixed(2)
            : "N/A"
          : values.matchedStrike?.callOption.openInterest
          ? parseFloat(values.matchedStrike?.callOption.ltp).toFixed(2)
          : "N/A";

        result = client?.margin;
        const newQty = (client?.margin / strike) * index?.lot_size;
        setValues((prev) => ({
          ...prev,
          quantity: roundOffQty(newQty),
        }));
      }

      setMargin(parseFloat(result).toFixed(2) || "0.00");
    } else {
      setMargin("0.00");
      setValues((prev) => ({
        ...prev,
        quantity: index?.lot_size,
      }));
    }
  }, [
    isChecked,
    values.action,
    values.ltp,
    index?.margin,
    client?.margin,
    values.matchedStrike,
    values.call,
    index.lot_size,
    setValues,
    roundOffQty,
  ]);

  let isDisabled =
    isEmpty(values.quantity) ||
    isEmpty(values.action) ||
    isEmpty(values.call) ||
    isEmpty(values.expiry) ||
    isEmpty(values.strike);

  return (
    <DraggableModal
      show={open}
      onHide={handleHide}
      title={
        <div className="flex gap-1">
          <div className="flex gap-1 font-semibold">
            <div>{user?.name}</div>
            <div>
              (
              {user?.broker === "icici"
                ? "ICICI Direct"
                : capitalize(user?.broker || "")}
              )
            </div>
          </div>
        </div>
      }
    >
      <div>
        {isEmpty(placeOrderIndexes) ? (
          <p>No Indexes currently found</p>
        ) : (
          <Fragment>
            <div className="flex align-items-center mb-3 dark">
              <div className="w-2/6">
                <label>Index</label>
              </div>
              <div className="flex items-end gap-2 justify-between text-xs w-4/6 overflow-x-auto custom-scrollbar">
                {indexValues.map((indexItem, index) => (
                  <div key={index}>
                    <div className="flex flex-col gap-2 w-max">
                      <label key={index} htmlFor={`index-${indexItem}`}>
                        {placeOrderIndexes
                          .find((item) => item.instrument_token === indexItem)
                          ?.name?.toUpperCase()}
                      </label>

                      <div className="flex justify-center">
                        <input
                          className="cursor-pointer"
                          type="radio"
                          id={`index-${indexItem}`}
                          name="index"
                          checked={values.index === indexItem}
                          value={indexItem}
                          onChange={handleChangeIndex}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Select Expiry */}
            <div className="flex align-items-center mb-3 dark">
              <div className="w-2/6">
                <label>Expiry</label>
              </div>
              <div className="d-flex justify-content-end align-items-stretch w-4/6">
                <InputGroup className="w-100">
                  <Button
                    size="sm"
                    className="boot-btn"
                    variant="secondary"
                    onClick={() => handleExpiryButtons("dec")}
                  >
                    <span className="bi bi-dash" />
                  </Button>
                  <Form.Select
                    size="sm"
                    className="all-normal-text"
                    name="expiry"
                    value={values.expiry}
                    onChange={handleExpiryChange}
                  >
                    <option>Select Expiry</option>
                    {!isEmpty(indexData) &&
                      indexData?.optionChain?.expiryDetailsDto?.expiryDates.map(
                        (date) => (
                          <option key={date} value={date}>
                            {formattedExpiry(date)}
                          </option>
                        )
                      )}
                  </Form.Select>
                  <Button
                    size="sm"
                    className="boot-btn"
                    variant="secondary"
                    onClick={() => handleExpiryButtons("inc")}
                  >
                    <span className="bi bi-plus" />
                  </Button>
                </InputGroup>
              </div>
            </div>

            {/* Select Strike */}
            <div className="flex align-items-center mb-3 dark">
              {/* <div style={{ width: "7.5rem" }}> */}
              <div className="w-2/6">
                <label>Strike</label>
              </div>
              <div className="d-flex justify-content-end align-items-stretch w-4/6">
                <div className="flex items-center gap-3">
                  <InputGroup className="w-100">
                    <Button
                      size="sm"
                      className="boot-btn"
                      variant="secondary"
                      onClick={() => handleStrikeButtons("dec")}
                    >
                      <span className="bi bi-dash" />
                    </Button>
                    <Form.Select
                      size="sm"
                      className={`all-normal-text ${
                        values.strike === "" ? "hide-dropdown" : ""
                      }`}
                      style={{ padding: "0px", paddingLeft: "4px" }}
                      value={values.strike}
                      name="strike"
                      onChange={handleChangeStrike}
                    >
                      <option>Select Strike</option>
                      {!isEmpty(indexData) &&
                        indexData?.optionChain?.optionChains.map((item) => (
                          <option
                            key={item.strikePrice}
                            value={Math.abs(item.strikePrice / 100)}
                          >
                            {Math.abs(item.strikePrice / 100)}
                          </option>
                        ))}
                    </Form.Select>
                    <Button
                      size="sm"
                      className="boot-btn"
                      variant="secondary"
                      onClick={() => handleStrikeButtons("inc")}
                    >
                      <span className="bi bi-plus" />
                    </Button>
                  </InputGroup>

                  <div className="flex gap-3 align-items-center">
                    <div>
                      <label>LTP</label>
                    </div>
                    <div>
                      <label className="ltp-bg w-14">
                        {isEmpty(values.matchedStrike)
                          ? values.ltp || "N/A"
                          : values.call === "PE"
                          ? values.matchedStrike?.putOption.openInterest
                            ? parseFloat(
                                values.matchedStrike?.putOption.ltp
                              ).toFixed(2)
                            : "-----"
                          : values.matchedStrike?.callOption.openInterest
                          ? parseFloat(
                              values.matchedStrike?.callOption.ltp
                            ).toFixed(2)
                          : "-----"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Distance */}
            <div className="flex align-items-center mb-4 dark">
              <div className="w-2/6">
                <label>Distance</label>
              </div>
              <div className="flex items-center justify-start w-4/6">
                <label style={{ marginRight: "10px" }}>
                  {values.distance || "N/A"}
                </label>
                <label htmlFor="" style={{ marginRight: "10px" }}>{`(${
                  isEmpty(values.distancePercentage)
                    ? 0
                    : values.distancePercentage
                }%)`}</label>
                <label htmlFor="">
                  <button
                    className="rounded-full w-4 h-4 text-black float-end"
                    onClick={() => fetchDistanceData(values.expiry)}
                  >
                    {loader ? (
                      <Spinner size="sm" />
                    ) : (
                      <span className="bi bi-arrow-clockwise h-8 w-8 font-black" />
                    )}
                  </button>
                </label>
              </div>
            </div>

            {/* Select Right */}
            <div className="flex align-items-center mb-4 dark">
              <div className="w-2/6">
                <label>Option</label>
              </div>
              <div className="flex gap-4 w-4/6">
                <FormGroup className="flex justify-center items-center">
                  <input
                    type="radio"
                    name="call"
                    value={"PE"}
                    onChange={handleChange}
                    checked={values.call === "PE"}
                    className="mr-2"
                  />
                  <label className="w-6">PE</label>
                </FormGroup>
                <FormGroup className="flex justify-center items-center">
                  <input
                    type="radio"
                    name="call"
                    value={"CE"}
                    onChange={handleChange}
                    checked={values.call === "CE"}
                    className="mr-2"
                  />
                  <label>CE</label>
                </FormGroup>
              </div>
            </div>

            {/* LTP */}
            {/* <div className="flex align-items-center mb-4">
              <div className="w-2/6">
                <label>LTP</label>
              </div>
              <div>
                <label>
                  {isEmpty(values.matchedStrike)
                    ? values.ltp || "N/A"
                    : values.call === "PE"
                    ? values.matchedStrike?.putOption.ltp
                    : values.matchedStrike?.callOption.ltp}
                </label>
              </div>
            </div> */}

            {/* Action */}
            <div className="flex align-items-center mb-4 dark">
              <div className="w-2/6">
                <label>Actions</label>
              </div>
              <div className="flex gap-4 w-4/6">
                <FormGroup>
                  <input
                    type="radio"
                    name="action"
                    value={"Buy"}
                    onChange={handleChange}
                    checked={values.action === "Buy"}
                    className="mr-2"
                  />
                  <label className="w-6">Buy</label>
                </FormGroup>
                <FormGroup>
                  <input
                    type="radio"
                    name="action"
                    value={"Sell"}
                    onChange={handleChange}
                    checked={values.action === "Sell"}
                    className="mr-2"
                  />
                  <label>Sell</label>
                </FormGroup>
              </div>
            </div>

            {/* Summary */}
            <div className="flex align-items-center mb-4 dark">
              <div className="w-2/6">
                <label>Summary</label>
              </div>
              <div className="all-normal-text w-4/6">
                {identifiers[values.index] || "N/A"}-
                {moment(values.expiry).format("DD-MMM-YYYY").toUpperCase()}-
                {isEmpty(values.matchedStrike)
                  ? values.strike
                  : Math.abs(values.matchedStrike?.strikePrice / 100)}
                -{values.call}
              </div>
            </div>

            {/* Avl Margin */}
            <div className="flex justify-between mb-3 items-center">
              <div className="flex items-center dark">
                <div style={{ width: "186px" }}>
                  <label>Available Margin</label>
                </div>
                <div className="w-4/6">
                  {isNaN(margin)
                    ? "0.00"
                    : parseFloat(margin).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </div>
              </div>

              <div className="flex items-center dark">
                <input
                  className="mr-2 cursor-pointer"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleAvailableMargin}
                  name="margin"
                />
                <div className="w-full">
                  <label>Use Margin</label>
                </div>
              </div>
            </div>

            {/* Quantity */}
            <div className="flex align-items-center mb-3 dark">
              <div className="w-2/6">
                <label>Quantity</label>
              </div>
              <div className="flex items-center gap-3 w-4/6">
                <div className="w-3/4">
                  <InputGroup className="w-100">
                    <Button
                      size="sm"
                      variant="secondary"
                      className="boot-btn"
                      onClick={() => handleQuantityButtons("dec")}
                    >
                      <span className="bi bi-dash" />
                    </Button>

                    <Form.Control
                      size="sm"
                      type="number"
                      name="quantity"
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleQuantityBlur}
                    />

                    <Button
                      className="boot-btn"
                      size="sm"
                      variant="secondary"
                      onClick={() => handleQuantityButtons("inc")}
                    >
                      <span className="bi bi-plus" />
                    </Button>
                  </InputGroup>
                </div>

                <div className="flex gap-3 align-items-center">
                  <div>
                    <label>Lots</label>
                  </div>
                  <div>
                    <label className="ltp-bg w-14">
                      {isNaN(values.quantity) || isEmpty(values.quantity)
                        ? 0
                        : Math.abs(
                            (
                              (values?.quantity || 0) / (index?.lot_size || 0)
                            ).toFixed(2)
                          )}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex align-items-center mb-4 dark">
              <div className="w-2/6">
                <label>Premium</label>
              </div>
              <div className="w-4/6">
                {!isNaN(values.quantity) && !isEmpty(values.quantity)
                  ? isEmpty(values.matchedStrike)
                    ? (values.quantity * values.ltp).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : values.call === "PE"
                    ? (
                        values.matchedStrike?.putOption?.ltp * values.quantity
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : (
                        values.matchedStrike?.callOption?.ltp * values.quantity
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                  : "N/A"}
              </div>
            </div>
            <Row className="align-items-center mb-3">
              <Col className="flex justify-center items-center gap-3">
                <Button
                  className="w-44 button-style"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  {submitLoader ? <Spinner size="sm" /> : "Place Order"}
                </Button>
                <Button className="w-44 button-style" onClick={handleHide}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </div>
    </DraggableModal>
  );
};

export default PlaceOrderV2;
