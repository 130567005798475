import React, { Fragment, useContext, useEffect, useState } from "react";
import OrderDisplay from "./OrderDisplay";
import { getPNLTotal, getTotalPNL } from "../../utils/positions";
import { SockContext } from "../../context/SocketContext";

const OrderCategory = ({
  collapse,
  orders,
  open,
  client,
  orderKey,
  pos,
  handleShowSquareOff,
  handleShowStopLoss,
  handleAllSquareOff,
  handleChangeInOrders,
  group,
  getGroupName,
}) => {
  const [posOrders, setPosOrders] = useState([]);

  const socket = useContext(SockContext).socket;

  useEffect(() => {
    const filteredOrders = orders?.filter((order) => order?.quantity);
    setPosOrders(filteredOrders);
  }, [orders]);

  useEffect(() => {
    socket?.on("ticker", (data) => {
      if (!pos.status) return;
      data = typeof data == "string" ? JSON.parse(data) : data;
      let tickData = data.raw_ticker;

      if (data.broker === "icici" && pos.broker === "icici") {
        let copy = [...posOrders];
        let findIndex = copy.findIndex(
          (item) =>
            item.strike_price == tickData.strike_price &&
            tickData.right.toLowerCase() == item.right.toLowerCase() &&
            item.expiry_date == tickData.expiry_date
        );

        if (findIndex >= 0) {
          copy[findIndex].ltp = parseFloat(data.last_price);
        }
        handleChangeInOrders(orderKey, copy, true);
      } else if (data.broker === "zerodha" && pos.broker === "zerodha") {
        let copy = [...posOrders];
        let findIndex = copy.findIndex(
          (item) => item.instrument_token == tickData.instrument_token
        );

        if (findIndex >= 0) {
          copy[findIndex].ltp = parseFloat(data.last_price);
        }
        handleChangeInOrders(orderKey, copy, true);
      }
    });
  }, [
    handleChangeInOrders,
    orderKey,
    pos.broker,
    pos.status,
    posOrders,
    socket,
  ]);

  const sortContract = (contract) => {
    return contract?.sort(
      (a, b) =>
        parseInt(a?.contract?.split("-")[4]) -
        parseInt(b?.contract?.split("-")[4])
    );
  };

  if (posOrders.length === 0) return null; // Return null if there are no orders to display

  return (
    <Fragment>
      {((!open.includes(`client-${client._id}-${pos.broker}-${orderKey}`) && (collapse == 'none' || collapse == 'client')) || (collapse == 'strategy' && open.includes(`client-${client._id}-${pos.broker}-${orderKey}`))) &&
        sortContract(posOrders).map((order) => (
          <OrderDisplay
            key={order.contract}
            order={order}
            client={client}
            pos={pos}
            group={group}
            handleShowSquareOff={handleShowSquareOff}
            handleShowStopLoss={handleShowStopLoss}
            setPosOrders={setPosOrders}
          />
        ))}
      <thead className="order-headers-bg">
        <tr className="margin-tr">
          <td
            style={{ width: "210px" }}
            className="text-center font-semibold all-normal-text table-head custom-margin-th"
          >
            Total
          </td>
          <td
            style={{ width: "200px" }}
            colSpan={8}
            className="table-head custom-margin-th"
          ></td>
          <td
            style={{ width: "80px" }}
            align="right"
            className={`${
              getPNLTotal(posOrders)?.pnl < 0 ? "loss-color" : "profit-color"
            } all-normal-text table-head custom-margin-th`}
          >
            {getPNLTotal(posOrders)?.prefix +
              getPNLTotal(posOrders)?.pnl.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </td>
          <td
            style={{ width: "200px" }}
            className="text-center ps-cl-sqoff all-normal-text table-head custom-margin-th"
            colSpan={4}
          >
            <span
              onClick={() =>
                handleAllSquareOff(orderKey, posOrders, pos.broker, client)
              }
              style={{ cursor: "pointer" }}
            >
              Square&nbsp;Off&nbsp;All&nbsp;Positions
            </span>
          </td>
        </tr>
      </thead>
    </Fragment>
  );
};

export default OrderCategory;
