import React, { Fragment, useCallback, useState } from "react";
import { getGroupedData } from "../../utils/getGroupedData";
import { useNavigate } from "react-router-dom";
import PlaceOrder from "./Modals/PlaceOrder";
import SquareOff from "./Modals/SquareOff";
import { useDispatch } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import { refreshPositionData } from "../../services/admin.service";
import isEmpty from "is-empty";
import SquareOffAll from "./Modals/SquareOffAll";
import PositionClientContainer from "./PositionClientContainer";
import PlaceOrderV2 from "./Modals/PlaceOrderV2";
import StopLoss from "./Modals/StopLoss";

const PositionClassifierNonGroup = ({
  data,
  fetchPositionsData,
  handleDeleteGroup,
  isModalOpen,
  openModal,
  closeModal,
}) => {
  // const [groupedData, setGroupedData] = useState(getGroupedData(data));
  const [user, setUser] = useState("");
  const [sqOffOrder, setOffOrder] = useState({});
  const [sqOffMode, setSqOffMode] = useState("");
  const [broker, setBroker] = useState("");
  const [open, setOpen] = useState([]);
  const [loader, setLoader] = useState([]);
  const [collapse, setCollapse] = useState("none");
  const [orderType, setOrderType] = useState("market");

  // Modal States
  const [showOrderPlace, setShowOrderPlace] = useState(false);
  const [show, setShow] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showStopLoss, setStopLoss] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [sqallOrder, setSqallOrder] = useState({
    user: {},
    broker: "",
    orders: [],
    orderKey: "",
  });
  const dispatch = useDispatch();

  const handleCollapseChange = (e) => {
    setOpen([]);
    if (e.target.value === "none") {
      setOpen([]);
    }
    setCollapse(e.target.value);
  };

  const handleShowSquareOff = (user, order, broker, mode, group_id) => {
    setUser(user);
    setOffOrder(order);
    setSqOffMode(mode);
    setBroker(broker);
    setShow(true);
    setGroupId(group_id);
  };

  const handleCloseSquareOff = () => {
    setUser("");
    setOrderType("market");
    setShow(false);
  };

  const handleShowStopLoss = (user, order, broker, group_id) => {
    setUser(user);
    setOffOrder(order);
    setBroker(broker);
    setStopLoss(true);
    setGroupId(group_id);
  };

  const handleCloseStopLoss = (
    clearqty,
    cleartriggerPrice,
    clearlimitPrice
  ) => {
    setUser("");
    clearqty("");
    cleartriggerPrice("");
    clearlimitPrice("");
    setStopLoss(false);
  };

  const handleAllSquareOff = (orderKey, orders, broker, user) => {
    setSqallOrder({ orderKey, broker, orders, user });
    setShowAll(true);
  };

  const handleCloseAllSquareOff = () => {
    setSqallOrder({ orderKey: "", broker: "", orders: [], user: {} });
    setShowAll(false);
  };

  const handleOpenPlaceOrder = (user, broker) => {
    setUser({ ...user, broker });
    setShowOrderPlace(true);
  };

  const handleClosePlaceOrder = () => {
    setShowOrderPlace(false);
    setUser("");
  };

  // console.log(groupedData);

  const toggleOpen = (id) => {
    setOpen((prev) => {
      console.log("prev: ", prev);
      let old = [...prev];
      if (old.includes(id)) {
        return old.filter((item) => item !== id);
      } else {
        old.push(id);
        return old;
      }
    });
  };

  const refreshGroupedData = useCallback();
  // (result, broker, index, orderIndex) => {
  //   let prevData = [...groupedData];
  //   let userObj = prevData[index];
  //   // console.log(userObj.positions[orderIndex], result)

  //   let filteredOrder = result.orders.reduce((prev, curr) => {
  //     let copyPrev = { ...prev };
  //     if (isEmpty(copyPrev[curr["underlying"]])) {
  //       copyPrev[curr["underlying"]] = [curr];
  //       return copyPrev;
  //     } else {
  //       copyPrev[curr["underlying"]].push(curr);
  //       return copyPrev;
  //     }
  //   }, {});

  //   userObj.positions[orderIndex] = { ...result, orders: filteredOrder };

  //   prevData[index] = userObj;
  //   setGroupedData(prevData);
  // },
  // [groupedData]

  const handleRefreshClient = useCallback(
    async (userId, broker, index, orderIndex) => {
      try {
        setLoader((prev) => {
          let newarr = [...prev];
          newarr.push(`loading-${userId}-${broker}-${index}`);
          return newarr;
        });
        const { success, message, result } = await refreshPositionData(
          userId,
          broker
        );
        if (success) {
          refreshGroupedData(result, broker, index, orderIndex);
          setLoader((prev) => {
            let newarr = prev.filter(
              (item) => item !== `loading-${userId}-${broker}-${index}`
            );
            return newarr;
          });
        } else {
          setLoader((prev) => {
            let newarr = prev.filter(
              (item) => item !== `loading-${userId}-${broker}-${index}`
            );
            return newarr;
          });
          dispatch(
            openToast({
              message,
              type: "error",
            })
          );
        }
      } catch (error) {
        dispatch(
          openToast({
            message: "Some error occurred",
            type: "error",
          })
        );
      }
    },
    [dispatch, refreshGroupedData]
  );

  return (
    <div className="ml-10 mr-10">
      <div className="d-flex justify-content-end align-items-center gap-3">
        <div>
          <b className="all-normal-text">Collapse By</b>
        </div>
        <div className="flex items-center gap-1">
          <div className="flex">
            <input
              id="strategy"
              className="all-normal-text cursor-pointer"
              name="collapse"
              type="radio"
              value={"strategy"}
              checked={collapse === "strategy"}
              onChange={handleCollapseChange}
            />{" "}
          </div>
          <div>
            <label className="all-normal-text" htmlFor="strategy">
              Strategy
            </label>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="flex">
            <input
              id="client"
              name="collapse"
              type="radio"
              className="all-normal-text cursor-pointer"
              value={"client"}
              onChange={handleCollapseChange}
              checked={collapse === "client"}
            />{" "}
          </div>
          <div>
            <label className="all-normal-text" htmlFor="client">
              Client
            </label>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="flex">
            <input
              className="all-normal-text cursor-pointer"
              id="none"
              name="collapse"
              type="radio"
              value={"none"}
              onChange={handleCollapseChange}
              checked={collapse === "none"}
            />{" "}
          </div>
          <div>
            <label className="all-normal-text" htmlFor="none">
              None
            </label>
          </div>
        </div>
      </div>
      {data?.map((client, index) => {
        return (
          <Fragment key={index}>
            {client.positions.map((pos, posIndex) => (
              <>
                <PositionClientContainer
                  collapse={collapse}
                  setCollapse={setCollapse}
                  client={client}
                  handleAllSquareOff={handleAllSquareOff}
                  handleOpenPlaceOrder={handleOpenPlaceOrder}
                  handleRefreshClient={handleRefreshClient}
                  handleShowSquareOff={handleShowSquareOff}
                  handleShowStopLoss={handleShowStopLoss}
                  index={index}
                  loader={loader}
                  open={open}
                  toggleOpen={toggleOpen}
                  pos={pos}
                  posIndex={posIndex}
                  handleDeleteGroup={handleDeleteGroup}
                  isModalOpen={isModalOpen}
                  openModal={openModal}
                  closeModal={closeModal}
                  fetchPositionsData={fetchPositionsData}
                />
              </>
            ))}
          </Fragment>
        );
      })}
      <PlaceOrderV2
        user={user}
        open={showOrderPlace}
        onHide={handleClosePlaceOrder}
        fetchPositionsData={fetchPositionsData}
      />
      <SquareOff
        show={show}
        onHide={handleCloseSquareOff}
        user={user}
        order={sqOffOrder}
        brokerage={broker}
        fetchPositionsData={fetchPositionsData}
        mode={sqOffMode}
        groupId={groupId}
        setOrderType={setOrderType}
        orderType={orderType}
      />
      <SquareOffAll
        show={showAll}
        onHide={handleCloseAllSquareOff}
        orders={sqallOrder.orders}
        broker={sqallOrder.broker}
        user={sqallOrder.user}
        orderKey={sqallOrder.orderKey}
        fetchPositionsData={fetchPositionsData}
      />
      <StopLoss
        show={showStopLoss}
        onHide={handleCloseStopLoss}
        user={user}
        order={sqOffOrder}
        brokerage={broker}
        fetchPositionsData={fetchPositionsData}
        groupId={groupId}
      />
    </div>
  );
};

export default PositionClassifierNonGroup;
